import React from 'react'
import getContactHref from '../utils/getContactHref'
import getIcon from '../utils/getIcon'
import Icon from './icon'


const Contacts = ({ contacts }) => {
  console.log(contacts)
  return (
    <div className="contacts">
      <h3 className="contacts-title">Contact me</h3>
      <ul className="contacts-list">
        {Object.keys(contacts).map((name) => (!contacts[name] ? null : (
          <li className="contacts-list__item" key={name}>
            <a
              className="contacts-list__item__link"
              href={getContactHref(name, contacts[name])}
              rel="noopener noreferrer"
              target="_blank"
            >
              <Icon name={name} icon={getIcon(name)} />
            </a>
          </li>
        )))}
      </ul>
    </div>
  )
}

export default Contacts