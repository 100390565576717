import { Link } from 'gatsby'
import React from 'react'

import Logo from '../../static/Avatar.jpg'
import Contacts from './contacts'
import useSiteMetadata from '../ hooks/useSiteMetadata'

const Sidebar = () => {

  const { author, copyright } = useSiteMetadata()

  return (
    <aside className="sidebar">
      <div className="sidebar-inner">
        <div className="about">
          <div className="cover-author-image">
            <Link to="/">
              <img src={Logo} alt={author.name}/>
            </Link>
          </div>
          <div className="author-name">{author.name}</div>
          <p>{author.bio}</p>
        </div>
        <Contacts contacts={author.contacts}/>
        <div className="copyright">
          <p>
            {new Date().getFullYear()} &copy; {copyright}
          </p>
        </div>
      </div>
    </aside>
  )
}

export default Sidebar
