import { useStaticQuery, graphql } from 'gatsby';

const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            copyright
            siteUrl
            title
            author {
              bio
              name
              photo
              contacts {
                codepen
                email
                facebook
                github
                gitlab
                instagram
                line
                linkedin
                medium
                rss
                soundcloud
                telegram
                twitter
                vkontakte
                weibo
                youtube
              }
            }
          }
        }
      }
    `
  );

  return site.siteMetadata;
};

export default useSiteMetadata;